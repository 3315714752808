export const BACKEND_PROD_URL = import.meta.env.VITE_BACKEND_PROD_URL
export const BACKEND_DEV_URL = import.meta.env.VITE_BACKEND_DEV_URL
export const BACKEND_LOCAL_URL = import.meta.env.VITE_BACKEND_LOCAL_URL
export const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT
export const POLOTNO_API_KEY = import.meta.env.VITE_POLOTNO_API_KEY
export const ALCHEMY_API_KEY = import.meta.env.VITE_ALCHEMY_API_KEY
export const WALLETCONNECT_PROJECT_ID = import.meta.env.VITE_WALLETCONNECT_PROJECT_ID
export const AIRSTACK_API_KEY = import.meta.env.VITE_AIRSTACK_API_KEY
export const NEYNAR_CLIENT_ID = import.meta.env.VITE_NEYNAR_CLIENT_ID
export const XMTP_ENV = import.meta.env.VITE_XMTP_ENV
export const PRIVY_APP_ID = import.meta.env.VITE_PRIVY_APP_ID

// Meme API
export const MEME_API_URL = import.meta.env.VITE_MEME_API_URL
export const MEME_API_USERNAME = import.meta.env.VITE_MEME_API_USERNAME
export const MEME_API_PASSWORD = import.meta.env.VITE_MEME_API_PASSWORD
export const FAL_API_KEY = import.meta.env.VITE_FAL_API_KEY
export const TIPLINK_API_KEY = import.meta.env.VITE_TIPLINK_API_KEY
export const REOWN_PROJECT_ID = import.meta.env.VITE_REOWN_PROJECT_ID
