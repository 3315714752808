export const LOCAL_STORAGE = {
	userAuthToken: 'user.auth.token',
	evmAuth: 'evm.auth',
	evmConnected: 'evm.connected',
	solanaAuth: 'solana.auth',
	lensAuth: 'lens.auth',
	farcasterAuth: 'farcaster.auth',
	userAddress: 'user.address',
	userAuthTime: 'user.auth.timestamp',
	ifUserEligible: 'ifUserEligible',
	hasUserSeenTheApp: 'hasUserSeenTheApp',
	dispatcher: 'lens.dispatcher',
	userGuideTour: 'user.guide.tour',
	braveShieldWarn: 'brave.shield.warn',
	userId: 'user.id',
	isWatermark: 'watermark',
	userLOA: 'user.LOA',
	privy: 'privy:token',
	FcComposerAuth: 'fc.composer.auth',
	actionType: 'action.type',
	fid: 'fid',
	twitterAuth: 'poster.twitter',
	twitterAuthURL: 'poster.twitter.url',
}
